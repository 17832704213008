import React from 'react'
import pr1 from '../../images/portfolio/img-1.jpg'
import pr2 from '../../images/portfolio/img-2.jpg'
import pr3 from '../../images/portfolio/img-3.jpg'
import pr4 from '../../images/portfolio/img-4.jpg'
import pr5 from '../../images/portfolio/img-5.jpg'
import pr6 from '../../images/portfolio/img-6.jpg'
import {Link} from 'react-router-dom'

import './style.css'

const Portfolio = (props) => {
    return(
        <section className="portfolio-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-10 offset-sm-1">
                        <div className="section-title-s6">
                            <span>Recent Work</span>
                            <h2>Please check out recent our case studie</h2>
                            <p> picture that he had recently cut out of an illustrated magazine and housed in a nice, gilded frame. It showed a lady fitted out with a fur</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="portfolio-grids clearfix">
                            <div className="grid">
                                <div className="img-holder">
                                    <img src={pr1} alt=""/>
                                </div>
                                <div className="details">
                                    <div className="content">
                                        <h3><Link to="/">Digital Merketing</Link></h3>
                                        <p className="cat">Agency</p>
                                    </div>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="img-holder">
                                    <img src={pr2} alt=""/>
                                </div>
                                <div className="details">
                                    <div className="content">
                                        <h3><Link to="/">Digital Merketing</Link></h3>
                                        <p className="cat">Agency</p>
                                    </div>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="img-holder">
                                    <img src={pr3} alt=""/>
                                </div>
                                <div className="details">
                                    <div className="content">
                                        <h3><Link to="/">Digital Merketing</Link></h3>
                                        <p className="cat">Agency</p>
                                    </div>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="img-holder">
                                    <img src={pr4} alt=""/>
                                </div>
                                <div className="details">
                                    <div className="content">
                                        <h3><Link to="/">Digital Merketing</Link></h3>
                                        <p className="cat">Agency</p>
                                    </div>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="img-holder">
                                    <img src={pr5} alt=""/>
                                </div>
                                <div className="details">
                                    <div className="content">
                                        <h3><Link to="/">Digital Merketing</Link></h3>
                                        <p className="cat">Agency</p>
                                    </div>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="img-holder">
                                    <img src={pr6} alt=""/>
                                </div>
                                <div className="details">
                                    <div className="content">
                                        <h3><Link to="/">Digital Merketing</Link></h3>
                                        <p className="cat">Agency</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Portfolio;