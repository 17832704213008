import React, {Fragment} from 'react';
import Header1 from '../../components/header'
import Hero2 from '../../components/hero2'
import ServicesSection from '../../components/ServicesSection'
import WhyChooseUs1 from '../../components/WhyChooseUs1'
import PricingSection from '../../components/pricing'
import TestimonialSlide from '../../components/Testimonial'
import Footer from '../../components/footerTop';
import Scrollbar from '../../components/scrollbar'


const HomePage =() => {
    return(
        <Fragment>
            <Header1/>
            <Hero2/>
            <WhyChooseUs1/>           
            <ServicesSection/>  
            <TestimonialSlide/>  
           
            <Footer subclass={'site-footer-s2'}/>
            <Scrollbar/>
        </Fragment>
    )
};
export default HomePage;