import React from 'react'
import Portfolio from '../../components/Portfolio'
import './style.css'
import pr1 from '../../images/project-single/img-1.jpg'
import pr2 from '../../images/check.png'

const CaseSingle = (props) => {

    return(
        <div className="project-single-section-wrap">
            <section className="project-single-section section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="project-single-content">
                                <div className="img-holder">
                                    <img src={pr1} alt=""/>
                                </div>
                                <div className="content-area">
                                    <div className="project clearfix">
                                        <div className="project-info">
                                            <ul className="clearfix">
                                                <li><span>Client:</span> Jonathon</li>
                                                <li><span>Location:</span> Newboston</li>
                                                <li><span>Date:</span> Nov 15, 2019</li>
                                                <li><span>Catetory:</span> SOE, SMM</li>
                                                <li><span>Time:</span> 1 Month</li>
                                                <li><span>Tags:</span> SEO, Business </li>
                                            </ul>
                                        </div>
                                        <div className="project-details">
                                            <h2>SEO project in London</h2>
                                            <p>Waved about helplessly as he looked. "What's happened to me?" he thought. It wasn't a dream. His room, a proper human room although a little too small, lay peacefully between its four familiar walls. A collection of textile samples lay spread out on the table - Samsa was a travelling salesman - and above it there hung a picture that he had recently cut out of an illustrated magazine and housed in a nice, gilded frame. It showed a lady fitted out with a fur hat and fur boa who sat upright, raising a heavy fur muff that covered the whole</p>
                                            <p>Collection of textile samples lay spread out on the table - Samsa was a travelling salesman - and above it there hung a picture that he had recently cut out of an illustrated magazine and housed in a nice, gilded frame. It showed a lady fitted out with</p>
                                        </div>
                                    </div>
                                    <div className="challange-solution-section clearfix">
                                        <div>
                                            <h3>Challenges</h3>
                                            <p>Compared with the size of the rest of him, waved about helplessly as he looked. "What's happened to me?" he thought. It wasn't a dream. His room, a proper human room although a little too small, lay peacefully between its four familiar walls. A collection of textile samples lay spread out on the table samsa was a travelling salesman</p>
                                            <ul>
                                                <li><span className="icon"><img src={pr2} alt=""/></span>Jugline of textile samples lay spread</li>
                                                <li><span className="icon"><img src={pr2} alt=""/></span>Doineg room although a little too small about</li>
                                                <li><span className="icon"><img src={pr2} alt=""/></span>Honen doing right out on the table samsa</li>
                                            </ul>
                                        </div>
                                        <div>
                                            <h3>Solutions</h3>
                                            <p>Compared with the size of the rest of him, waved about helplessly as he looked. "What's happened to me?" he thought. It wasn't a dream. His room, a proper human room although a little too small, lay peacefully between its four familiar walls. A collection of textile samples lay spread out on the table samsa was a travelling salesman</p>
                                            <ul>
                                                <li><span className="icon"><img src={pr2} alt=""/></span>Jugline of textile samples lay spread</li>
                                                <li><span className="icon"><img src={pr2} alt=""/></span>Doineg room although a little too small about</li>
                                                <li><span className="icon"><img src={pr2} alt=""/></span>Honen doing right out on the table samsa</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Portfolio/>
        </div>
    )
}

export default CaseSingle;