import React from 'react'
import {Link} from 'react-router-dom'
import { useLocation } from "react-router-dom";


import './style.css'

const ServicesSection = (props) => {

    const location = useLocation();

    console.log('location',location);

    return(
        <section className="services-section section-padding">
            
            {! props.isPage &&
            <div className="shape">               
                </div>
            }

            <div className="container">

                {! props.isPage &&
                    <div className="row">
                        <div className="col col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-10 offset-md-1">
                            <div className="section-title-s3">
                                <span>Services</span>
                                <h2>Specialist in the following services</h2>                            
                            </div>
                        </div>
                    </div>
                }

                <div className="row">
                    <div className="col col-xs-12">
                        <div className={'service-grids ' + (props.isPage ? 'service-page' : ' service-home ')}>
                            <Link to='/services/websites'>
                            <div className={'grid ' + (location.pathname == '/services/websites' ? 'active': '')}>
                                <i className="fi flaticon-web-site"></i>
                                <h3>Websites</h3>
                               
                                {! props.isPage &&
                                    <p>Design & Development. From simple brochure sites to advanced CMS driven websites.
                                    </p>
                                }
                            </div>
                            </Link>
                            <Link to='/services/mobile-apps'>
                            <div className={'grid ' + (location.pathname == '/services/mobile-apps' ? 'active': '')}>
                                <i className="fi flaticon-smartphone"></i>
                                <h3>Mobile Apps</h3>

                                {! props.isPage &&
                                    <p>Cross platform apps for iOS, Android & Windows Tablets. App & Play Store deployment.</p>
                                }
                            </div> 
                            </Link>  
                            <Link to='/services/pwa'>
                            <div className={'grid ' + (location.pathname == '/services/pwa' ? 'active': '')}>
                                <i className="fi flaticon-internet"></i>
                                <h3>Web Apps & PWAs</h3>
                                {! props.isPage &&
                                    <p>Utilise browser based technology to build web based software or cross platform PWAs.</p>
                                }
                            </div> 
                            </Link>   
                            <Link to='/services/hosting'>                  
                            <div className={'grid ' + (location.pathname == '/services/hosting' ? 'active': '')}>
                                <i className="fi flaticon-servers"></i>
                                <h3>Domain & Hosting</h3>
                                {! props.isPage &&
                                    <p>I can help you register or transfer your existing domain, and also host your website.</p>
                                }
                            </div> 
                            </Link>
                            <Link to='/services/software'> 
                            <div className={'grid ' + (location.pathname == '/services/software' ? 'active': '')}>
                                <i className="fi flaticon-api"></i>
                                <h3>Integrations</h3>
                                {! props.isPage &&
                                    <p>Integrate your website/software with existing systems and APIs (Stripe, Xero, Shopify, CRMs, etc).</p>
                                }
                            </div>
                            </Link>
                            <Link to='/services/contract'>
                            <div className={'grid ' + (location.pathname == '/services/contract' ? 'active': '')}>
                                <i className="fi flaticon-coding"></i>
                                <h3>Contracting</h3>
                                {! props.isPage &&
                                    <p>As well as working directly for clients, I am available for contract work on your project.</p>
                                }
                            </div>
                            </Link>
                        </div>
                    </div>                    
                </div>

            {location.pathname == '/services/websites' &&
                <div className="row service-detail" style={{marginTop:'40px'}}>
                    <div className="col col-xs-12">
                        <h1>Websites</h1>
                        <p>
                           I am a specialist in websites of all types, from small businesses websites with just a few pages, to websites with heavily customised functionality
                            that are driven by a Content Management System (CMS) tailored to your business needs. 
                        </p>
                        <p>My extensive knowledge means I can add any functionality you need, such as online payments, booking forms, etc. 
                            Your website can even be integrated with other systems your business uses such as inventory/product management or accounting systems.                            
                        </p>
                        <p>
                            Each step of your website can be taken care of, so if you find the idea 
                            of launching a website intimidating there is no need to worry as you don't need to find a different company to take care of each step!
                        </p>
                        <p>
                            Typical steps on each website include the following - all of which I can take care of:

                            <ul>
                                <li>Initial concept / Analysis </li>
                                <li>Design / User interface </li>
                                <li>Development </li>
                                <li>Test</li>
                                <li>Deploy & Host on swatt.co.nz server (including using your own custom domain)</li>
                                <li>Online Marketing (via Google Adwords, Social Media, etc)</li>
                                <li>Analytics</li>
                            </ul>
                        </p>
                        <p>All websites I develop are Search Engine Optimised (SEO), mobile friendly and 'responsive' so they will work on all modern browsers and platforms, 
                            from phone screens and tablets to large desktop screens.</p>

                        <p>I am a specialist at building easy to use Content Management Systems (CMS), that means you or your staff
                            can sign in to a web interface and manage any content (text/images/documents, etc) 
                            via an easy to use system, rather than getting a developer to make all future updates.
                        </p>
                    </div>
               </div>
            }

            {location.pathname == '/services/mobile-apps' &&
                <div className="row service-detail" style={{marginTop:'40px'}}>
                    <div className="col col-xs-12">
                        <h1>Mobile Apps</h1>
                        <p>
                            Do you need an App to help solve or automate a business problem? 
                            I can create cross platform apps that run on iOS, Android and even Windows tablets.
                        </p>
                        <p>Example of business Apps I can make include: Calculators, Sales Tools, Inventory management, Product tracking/scanning.   
                            I can also help you to launch App ideas you may have, such as Social Media, Online Communities, Educational tools, etc.              
                        </p>
                        <p>
                           Apps can be distributed to just your team, or deployed in the App/Play stores to a public audience. 
                        </p>  
                    </div>
               </div>
            }


            {location.pathname == '/services/pwa' &&
                <div className="row service-detail" style={{marginTop:'40px'}}>
                    <div className="col col-xs-12">
                        <h1>Web Apps & PWAs</h1>
                        <p>
                            You probably use lots of web based Apps, including PWA's, without even realising it!
                            These days, with advancements in browser technologies, Apps can run in a web browser 
                            and don't necessarily need to be downloaded and installed, or run as an installed app via the Stores. 
                        </p>
                        <p>Using Progressive Worker App (PWA) technlogies means web based apps can provide a range of features
                            that in the past were only typically available via native apps, such as push notifications, offline capability,
                            and ability to install to the device home screen.                        
                        </p>
                        <p>
                           There are a lot of advantages to web based apps and PWAs, including reduced development time, easier deployment and ability to 
                           work across all environments.
                        </p>
                        <p>PWAs I develop are responsive (they look great on all screen sizes, from desktop to mobile), and cross platform (iOS/Android/Windows) as they will run on all modern 
                            browsers (Chrome, Edge, Safari, etc).</p>

                        <p>Even a typical website can often be enhanced by PWA features, for example an ability to load and use the website from previously cached content 
                            if no internet connection is available.</p>
                                            
                    </div>
               </div>
            }

            {location.pathname == '/services/hosting' &&
                <div className="row service-detail" style={{marginTop:'40px'}}>
                    <div className="col col-xs-12">
                        <h1>Domain & Hosting</h1>
                        <p>
                           Once a website is built, it needs hosting (the server that the website code sits on) and a domain name so that
                           it can be accessed.
                        </p>
                        <p>Setting up a domain name and hosting can be intimidating for non technical users, but SWATT can help you with everything; including 
                            registering a domain name if you don't already have one (or transferring an existing domain name) and finally hosting your site on our 
                            servers.                      
                        </p>
                        <p>                       
                           Hosting costs usually incur a small monthly fee, that will differ depending on the specifics of your website.
                           The domain registration cost is around $28 per year for a .co.nz address.
                        </p>    
                        <p>
                          SWATT Software is a registered member of the Microsoft Partner Network and host all of our websites and applications on Microsoft Azure infrastructure. 
                           All websites and databases are backed up frequently.
                        </p>                                        
                    </div>
               </div>
            }

            {location.pathname == '/services/software' &&
                <div className="row service-detail" style={{marginTop:'40px'}}>
                    <div className="col col-xs-12">
                        <h1>Integrations</h1>
                       
                        <p>These days there are a range of great online subscription based services so it doesn't always make sense to
                            build something from scratch (for example, if you want to launch an e-commerce store it might make sense to use Shopify.com rather than
                             building a bespoke store). 

                            Many of these services have 'APIs' that allow data to be securely exposed to other systems, 
                            which opens up a range of possibilites for how you can use your data and streamline/automate
                            business processes.
                            
                        </p>
                     
                        <p>Modern software architecture often involves integrating multiple services/components together, rather than 'reinventing the wheel'.
                        I can help you securely integrate with different systems, including:

                            <ul>
                                <li>Integrate your app/website with online services (Xero, Shopify, etc) </li>
                                <li>Online Payments (Stripe, PayPal, etc) </li>
                                <li>Integrate your app/website with your internal business software and systems</li>
                    
                                <li>API Development (so you can expose
                                    your data to other systems,
                                    I specialise in building highly secure REST APIs).
                                </li>
                                                      
                            </ul>
                        </p>
                      
                    </div>
               </div>
            }

            {location.pathname == '/services/contract' &&
                <div className="row service-detail" style={{marginTop:'40px'}}>
                    <div className="col col-xs-12">
                        <h1>Contracting / Consultancy</h1>
                        <p>
                           SWATT Softwares founder, Simon Watt, is available for contract/freelance work to other web/software companies.
                           My rate is flexible depending on the project.
                           Simon has nearly 20 years experience developing software, websites and apps for some of New Zealand's leading companies, including 
                            architecture and development of scalable and secure platforms with 150k+ users.                           
                        </p>                       
                        <p>
                            My skills include:
                        </p>

                            <b>General</b>
                            <ul>
                                <li>Worked as a developer on over 100 websites & apps</li>
                                <li>Software architecture</li>
                                <li>Technical documentation</li>
                                <li>Github/BitBucket</li>
                                <li>CI/CD (Bitrise.io, BitBucket Pipelines, GitHub Actions, etc)</li>
                                <li>Different project management methodologies, including Agile/Scrum</li>
                                <li>Worked on both Bespoke and SaaS products</li>
                                <li>Integrated solutions with many online services (Stripe, Xero, Shopify, Slack, etc) </li>
                            </ul>

                            <b>Front End</b>
                            <ul>
                                <li>HTML/CSS/SCSS</li>
                                <li>JavaScript/ES6+</li>
                                <li>ReactJS (and numerous other JavaScript libraries)</li>
                                <li>Web based ‘Apps’, Single Page Apps (SPAs), PWAs (including offline capable)</li>
                                <li>iOS/Android apps via Cordova framework</li>
                                <li>Strong UI/UX skills (including developing several responsive apps/websites without the input of a designer, and experience with MUI)</li>
                                <li>HTML5 storage technologies (e.g IndexedDB)</li>
                                <li>Deployments to App Store and Play Store (and enterprise distributions on iOS)</li>
                             </ul>

                             <b> Back End / Server</b>
                             <ul>
                                 <li>C#</li>
                                 
                                 <li>ASP.NET Framework</li>
                                 <li>ASP.NET Core </li>
                                 <li>ASP.NET Web API (including securing via oAuth2.0 flows)</li>
                                 <li>Entity Framework</li>
                                 <li>Auth0 platform</li>
                                 <li>Umbraco CMS (C#/Razor)</li>
                                 <li>MSSQL Server</li>
                                 <li>AWS (including EC2, S3, CloudFront)</li>    
                                 <li>Azure (including containerized APIs with Azure App Service, Static Web Apps, Azure SQL Database, VM's, etc)</li>                       
                            </ul>
                       
                        <p style={{marginTop:'30px'}}>View more of my work experience on <a href="https://www.linkedin.com/in/simon-watt-b400542">LinkedIn</a></p>
                        <p style={{marginTop:'10px'}}>To find out if I'm available for contract work and current availability, please <a href="/contact">contact me</a>.</p>                        
                    </div>
               </div>
            }

            {props.isPage && location.pathname != '/services' &&
                                  
             <Link to="/contact"
             style={{marginTop:'30px'}}
             className="theme-btn-s3">Want a Quote? Get in Touch<i className="ti-angle-right"></i></Link>
                  
            }
              
            </div> 
          
        </section>
    )
}

export default ServicesSection;