
import React, { Component } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import './style.css'

class TestimonialSlide extends Component {
    render() {
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            autoplaySpeed: 6000,
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            margin: 10,
            loop: true,
            responsive: [
                {
                  breakpoint: 1200,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true
                  }
                },
                {
                  breakpoint: 991,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                },
                {
                  breakpoint: 576,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ]
        };
        return (
          <section className={`testimonials-section ${this.props.testclass}`}>
              <div className="container">

              <div className="testimonials-area">
                            
                         
                            <div className="section-title-s4">
                                <span>Recent Work</span>
                                <h2>Check out some recent work!</h2>
                            </div>
                              <Slider {...settings}>   

                             {/* <div className="grid">
                                        <div className="row"> 
                                            <div className="col col-lg-6 col-md-12 col-12">
                                                <img src='/images/consignhq-small.png' alt="ConsignHQ" style={{maxWidth:'100%', marginBottom:'30px'}} />
                                            </div>  

                                            <div className="col col-lg-6 col-md-12 col-12">                         
                                              <div className="quote">
                                              
                                               <a href="https://consignhq.com" target="_blank"><h2  style={{color:'#fc6e36'}}>consignhq.com</h2></a>
                                                  <p>Web based app</p>
                                                  <p style={{marginTop:'20px'}}>
                                                  ConsignHQ is web based POS and consignment management software that makes it easy to run a shop that 
                                                  sells products on a consignment basis. ConsignHQ is a 'SaaS' (Software as a Service) product with a monthly subscription model.
                                                  </p>
                                                 
                                                  <p style={{marginTop:'20px'}}>
                                                    
                                                  </p>                                               
                                              </div>
                                              <div className="client-info">                                            
                                              <a href="https://consignhq.com" target="_blank">consignhq.com</a>
                                              </div>
                                            </div>
                                        </div>
        </div>   */}

                              <div className="grid">
                                        <div className="row"> 
                                            <div className="col col-lg-6 col-md-12 col-12">
                                                <img src='/images/bellathefairy.png' alt="Bella the Fairy" style={{maxWidth:'100%', marginBottom:'30px'}} />
                                            </div>  

                                            <div className="col col-lg-6 col-md-12 col-12">                         
                                              <div className="quote">
                                               <a href="https://bellathefairy.co.nz" target="_blank"><h2  style={{color:'#fc6e36'}}>bellathefairy.co.nz</h2></a>
                                                  <p>Small business website to generate booking leads</p>
                                                  <p style={{marginTop:'20px'}}>
                                                  A simple (cost effective) website made for a local face painting/party company. It's an example of a simple way to advertise your local business, optimized for search engines (SEO), with an online contact or booking form which will generate leads that are sent directly to your Email/SMS/WhatsApp (or whatever platform you prefer).
                                                  </p>
                                                 
                                                  <p style={{marginTop:'20px'}}>
                                                    
                                                  </p>                                               
                                              </div>
                                              <div className="client-info">                                            
                                                                                 
                                             <a href="https://bellathefairy.co.nz" target="_blank">bellathefairy.co.nz</a>
                                         
                                              </div>
                                            </div>
                                        </div>
                                  </div>   

                                <div className="grid">
                                        <div className="row"> 
                                            <div className="col col-lg-6 col-md-12 col-12">
                                                <img src='/images/loosethreadz-small.png' alt="Loose Threadz Screenshot" style={{maxWidth:'100%', marginBottom:'30px'}} />
                                            </div>  

                                            <div className="col col-lg-6 col-md-12 col-12">                         
                                              <div className="quote">
                                              
                                               <h2>Loose Threadz POS system</h2>
                                                  <p>Custom web based software</p>
                                                  <p style={{marginTop:'20px'}}>
                                                  Loose Threadz is a resale clothing store that sells products on consignment. The project involved creating custom web based software to manage POS and help track consignments throughout its lifecycle, 
                                                    including generating on demand reports for consignors and an
                                                    ability to generate product tags and barcodes so transactions 
                                                    can be easily scanned and the consignment tracked from drop off, to sale, to consignor payout.
                                                  </p>
                                                 
                                                  <p style={{marginTop:'20px'}}>
                                                    
                                                  </p>                                               
                                              </div>
                                              <div className="client-info">                                            
                                              
                                              </div>
                                            </div>
                                        </div>
                                    </div>                                            

                                  <div className="grid">

                                      <div className="row"> 

                                        <div className="col col-lg-6 col-md-12 col-12">
                                            <img src='/images/gtv2-small.png' alt="Gardens To Visit screenshot" style={{maxWidth:'100%', marginBottom:'30px'}} />
                                        </div>  

                                        <div className="col col-lg-6 col-md-12 col-12">                         
                                          <div className="quote">
                                          <a href="https://gardenstovisit.co.nz" target="_blank"><h2  style={{color:'#fc6e36'}}>gardenstovisit.co.nz</h2></a>
                                              <p>Content managed website
                                              </p>
                                              <p style={{marginTop:'20px'}}>
                                              Gardens to Visit is a popular website for showcasing New Zealand gardens. The website is mobile friendly, ranks highly in Google search results, and features an advanced Content Management System (CMS) that 
                                                allows even non technical garden owners to upload photos and update their garden details via their own unique login.
                                              </p> 
                                          </div>
                                          <div className="client-info">                                            
                                             <a href="https://gardenstovisit.co.nz" target="_blank">gardenstovisit.co.nz</a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>


                                    <div className="grid">

                                    <div className="row"> 

                                      <div className="col col-lg-6 col-md-12 col-12">
                                          <img src='/images/corporatesport-small.png' alt="Corporate Sport screenshot" style={{maxWidth:'100%', marginBottom:'30px'}} />
                                      </div>  

                                      <div className="col col-lg-6 col-md-12 col-12">                         
                                        <div className="quote">
                                        <h2  style={{color:'#fc6e36'}}>Corporate Sport</h2>
                                            <p>Content managed website / admin system
                                            </p>
                                            <p style={{marginTop:'20px'}}>
                                            Development included a custom backend system (using Umbraco CMS) 
                                            for allowing admins staff to record results, auto calculate points tables, manage schedules and take players registrations and fees online.
                                            </p> 
                                        </div>
                                        <div className="client-info">                                            
                                         
                                        </div>
                                      </div>
                                    </div>
                                    </div>

                                    
                                                    

                                </Slider>
                              </div>
                        </div>                    
               
            </section>
            );
        }
    }
    
    export default TestimonialSlide;
          
          
          
          
