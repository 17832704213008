import React from 'react'
import {Link}  from 'react-router-dom'
import rkt1 from '../../images/banner3.png'
import './style.css'

const Hero2 = (props) => {

    return(
        <>
        <section className="hero-slider hero-style-2">
            <div className="hero-container">
                <div className="hero-inner">
                    <div className="container">
                        <div className="row">
                            <div className="col col-lg-6 col-md-8 col-12">
                                
                                <div className="slide-title">
                                    <span>SWATT Software - Auckland, NZ</span>    
                                    <h2 style={{lineHeight: '1.3'}}>Website & App Development
                                    </h2>
                                </div>
                                <div className="slide-text">
                                    <p>Quality websites, apps, custom software and integrations.
                                        Available for work directly to clients or 
                                        on a freelance basis to other software companies.
                                    </p>
                                
                                </div>
                                <div className="clearfix"></div>
                                <div data-swiper-parallax="500" className="slide-btns">
                                    <Link to="/about" className="theme-btn">About</Link>
                                </div>
                            </div>                        

                            <div className="d-none d-md-block col col-lg-6 col-md-4 col-12">
                                <img src={rkt1} alt="App & Website Development" />
                            </div>
                        </div>
                    </div>
                </div>              
            </div> 
        </section>

        <div className="d-block d-md-none" style={{textAlign:'center', marginBottom:'40px'}}>
            <img src={rkt1}  alt="App & Website Development" style={{maxWidth:'75%'}}/>
        </div>     
        </>

    )
}

export default Hero2;
