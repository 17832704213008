import React, {Fragment} from 'react';
import Header from '../../components/header'
import PageTitle from '../../components/pagetitle'
import About from '../../components/about'
import TestimonialSlide from '../../components/Testimonial'
import Footer from '../../components/footerTop';
import Scrollbar from '../../components/scrollbar'


const AboutPage =() => {
    return(
        <Fragment>
            <Header/>
            <PageTitle pageTitle={'About'}/>
           
            <About/>            
      
            <TestimonialSlide/>
            <Footer subclass={'site-footer-s2'}/>
            <Scrollbar/>
        </Fragment>
    )
};
export default AboutPage;