import React from 'react'
import {Link} from 'react-router-dom'

//import partnerImage from '../../images/partnerLogo.png'
import './style.css'

const About = (props) => {
    return(
        <section className="about-us-section section-padding">
            <div className="container" style={{marginTop:'40px'}}>
                <div className="row">
                    <div className="col col-lg-6 col-12">
                        <div className="section-title-s4">
                            <span>About</span>                          
                        </div>
                        <h5> Who</h5>

<p>SWATT software is a company launched by Simon Watt, who has nearly 20 years of industry experience. I am available for work directly to clients 
    or on a freelance/contract basis to other software companies.
</p><p>                       
    I have worked on projects spanning several industries including Medical, Agriculture, Horticulture, E-Commerce and Online Learning, using 
    a variety of different technologies.
</p>
    {/*His role has 
    ranged from web development to systems architecture and being the technical lead at a digital agency working on projects - some with over 100k users -
    for many of New Zealand's most well known companies.*/}
     <p>
    SWATT Software Limited is a registered member of the Microsoft Partner Network.
    All of our servers and applications are hosted on Microsoft Azure infrastructure. Websites, apps and their data are backed up frequently.
    </p>



    <h5>Approach</h5>  
    <p>
     I primarily work remotely - although if you are in Auckland, 
     New Zealand, I can meet up and attend meetings as needed.</p>
     <p>For clients I work with directly, 
     each step of the process is achievable 
     through emails, video calls (Skype/Slack/Zoom, etc), phone calls, whatever you prefer.</p>
   
</div>
</div>

                        <div className="about-details">
                        <h5> What</h5>
                        <p>
                                I have experience in multiple digital disciplines; 
                                that means you don't need to find a different company for each part of the process (e.g, analysis, design, development, testing, web hosting, etc).  
                                I have each step covered and can simplify the unknown into terms you can understand.</p>
                        <p>No job is too big or small; I've worked on projects ranging 150k+ users to simple websites. I am also available for contract/freelance work directly for other software companies.
                        </p>
                       

                   
                    <div className="col col-lg-6 col-12">
                        <div className="img-holder">
                            <img src='/images/about.jpg' alt="About"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;