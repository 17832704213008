import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import './style.css'
import {useState, useEffect} from 'react';


const Scrollbar = () => {

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {   
        window.addEventListener("scroll", listenToScroll);
        return () => 
           window.removeEventListener("scroll", listenToScroll); 
      }, [])

      const listenToScroll = () => {
        let heightToHideFrom = 800;
        const winScroll = document.body.scrollTop || 
            document.documentElement.scrollTop;
           
        if (winScroll > heightToHideFrom) { 
           ! isVisible &&      // to limit setting state only the first time         
             setIsVisible(true);
        } else {
             setIsVisible(false);
        }  
      };


    return(
        <div className="col-lg-12">
            <div className="header-menu">
            {
                isVisible && 
                    <ul className="smothscroll">
                        <li><AnchorLink href='#scrool'><i className="fa fa-arrow-up"></i></AnchorLink></li>
                    </ul>
                }
            </div>
        </div>
        
    )
}

export default Scrollbar;
