import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './style.css';

const menus = [
    {
        id: 1,
        title: 'Home',
        link: '/home',       
    },
    {
        id: 2,
        title: 'About',
        link: '/',
        submenu: [
            {
                id: 3,
                title: 'About',
                link: '/about'
            },
            {
                id: 4,
                title: 'Contact',
                link: '/contact'
            }  
        ]
    },
    {
        id: 3,
        title: 'Services',
        link: '/services',
        submenu: [
            {
                id: 4,
                title: 'Services',
                link: '/services'
            },
            {
                id: 5,
                title: 'Websites',
                link: '/services/websites'
            },
            {
                id: 6,
                title: 'Mobile Apps',
                link: '/services/mobile-apps'
            },
            {
                id: 7,
                title: 'Web Apps & PWAs',
                link: '/services/pwa'
            },
            {
                id: 8,
                title: 'Domain Registration & Website Hosting',
                link: '/services/hosting'
            },
            {
                id: 9,
                title: 'Integrations',
                link: '/services/software'
            },
            {
                id: 10,
                title: 'Contracting',
                link: '/services/contract'
            },
        ]
    },

    {
        id: 11,
        title: 'Recent Work',
        link: '/case'       
    },
    {
        id: 12,
        title: 'Contact',
        link: '/contact',        
    } 
    
    
]


export default class MobileMenu extends Component {

    state = {
        isMenuShow: false,
        isOpen: 0,
    }

    menuHandler = () => {
        this.setState({
            isMenuShow: !this.state.isMenuShow
        })
    }

    setIsOpen = id => () => {
        this.setState({
            isOpen: id === this.state.isOpen ? 0 : id
        })
    }

    render() {

        const { isMenuShow, isOpen } = this.state;

        return (
                        
            <div> 
                <div className='company-name'>                     
                    <Link to='/' style={{display: 'inline-block', position: 'relative', zIndex: 1}}>
                        <span style={{display: 'inline-block'}} >
                            <object style={{position: 'relative', zIndex: -1}} data="/images/logo.svg" width="140px" />
                        </span>
                    </Link>
                </div>
               
                <div className={`mobileMenu ${isMenuShow ? 'show' : ''}`}>

                    
                    <i style={{fontSize:'26px',color:'#fff', cursor: 'pointer', marginLeft:'20px', marginTop:'20px'}} className="fa fa-close"  onClick={this.menuHandler} aria-hidden="true"></i>                        
                                       

                    <ul className="responsivemenu">
                        {menus.map(item => {
                            return (
                                <li key={item.id}>
                                    {item.submenu ? <p onClick={this.setIsOpen(item.id)}>
                                        {item.title}
                                        {item.submenu ? <i className="fa fa-angle-right" aria-hidden="true"></i> : ''}
                                    </p> : <Link to={item.link}>{item.title}</Link>}
                                    {item.submenu ?
                                    <div className='card' style={{display: item.id === isOpen ? 'block' : 'none'}}>
                                       
                                                <ul className='card-body'>
                                                    {item.submenu.map(submenu => (
                                                        <li key={submenu.id}><Link className="active" to={submenu.link}>{submenu.title}</Link></li>
                                                    ))}
                                                </ul>
                                           
                                    </div>
                                    : ''}
                                </li>
                            )
                        })}
                    </ul>

                </div>
               
                <div className="showmenu" onClick={this.menuHandler}><i className="fa fa-bars" aria-hidden="true"></i></div>
            </div>
            
        )
    }
}
