import React, {Fragment} from 'react';
import Header from '../../components/header'
import PageTitle from '../../components/pagetitle'
import CaseSingle from '../../components/casesingle'
import Footer from '../../components/footerTop';
import Scrollbar from '../../components/scrollbar'


const CaseSinglePage =() => {
    return(
        <Fragment>
            <Header/>
            <PageTitle pageTitle={'Project single'}/>
            <CaseSingle/>
            <Footer subclass={'site-footer-s2'}/>
            <Scrollbar/>
        </Fragment>
    )
};
export default CaseSinglePage;