import React, {Fragment} from 'react';
import Header from '../../components/header'
import PageTitle from '../../components/pagetitle'
import TestimonialSlide2 from '../../components/Testimonial2'
import Footer from '../../components/footerTop';
import Scrollbar from '../../components/scrollbar'


const TestimonialPage2 =() => {
    return(
        <Fragment>
            <Header/>
            <PageTitle pageTitle={'Testimonial'}/>
            <TestimonialSlide2 tmclass={'testimonials-section-s2-pg'}/>
            <Footer subclass={'site-footer-s2'}/>
            <Scrollbar/>
        </Fragment>
    )
};
export default TestimonialPage2;