import React from 'react'
import {Link} from 'react-router-dom'
import './style.css'

const PricingSection = (props) => {
    return(
        <section className="pricing-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg- offset-lg-2 col-md-8 offset-md-2 col-sm-10 offset8-sm-1">
                        <div className="section-title-s2">
                            <span>Pricing</span>
                            <h2>Price Guide (NZD)</h2>
                            <p>A streamlined approach means we can offer great pricing. As each project is different 
                                the exact price will depend on your requirements. We will quote a fixed
                                price after we discuss your project needs and gather more specifics. Initial consultation is <b>free</b>.</p>                      
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="pricing-grids">
                            <div className="grid">
                                <div className="pricing-header">
                                    <h5>Brochure website</h5>
                                    <i className="fi flaticon-diamond"></i>
                                    <h1 className="price"><span>$</span>1,200</h1>
                                    <p></p>
                                </div>
                                <div className="pricing-body">
                                    <ul>
                                        <li>Several Pages (such as About, Pricing, Testimonials)</li>
                                        <li>Mobile Friendly 'responsive' design</li>
                                        <li>Contact / Enquiry Form</li>                                       
                                        <li>Great looking design customised to fit your companies branding</li> 
                                        <li>SEO friendly / Website Analytics</li>    
                                        <li>Your own domain</li>                                                                          
                                        <li>Customer Support</li>
                                    </ul>
                                    <Link to="/case" className="theme-btn-s3">View Recent Work</Link>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="hilight-tag">Popular</div>
                                <div className="pricing-header">
                                    <h5>Advanced Website</h5>
                                    <i className="fi flaticon-speaker"></i>
                                    <h1 className="price"><span>$</span>3,000+</h1>
                                    <p></p>
                                </div>
                                <div className="pricing-body">
                                    <ul>
                                        <li>All the features of the Brochure website</li>
                                        <li>Content Management (CMS) ability, so you can update
                                             content via an easy to use web interface without needing a developer.
                                        </li>                                       
                                        <li>Customised functionality specific to your business needs</li>                                        
                                        <li>Integrate with other systems (e.g Paypal/Stripe/Xero)</li>  
                                        <li>User Sign in, etc</li>                                        
                                    </ul>
                                    <Link to="/case" className="theme-btn-s3">View Recent Work</Link>
                                </div>
                            </div>
                            <div className="grid">
                                
                                <div className="pricing-header">
                                    <h5> Mobile App</h5>
                                    <i className="fi flaticon-coffee-cup"></i>
                                    <h1 className="price"><span>$</span>4,000+</h1>                                    
                                </div>
                                <div className="pricing-body">
                                    <ul>
                                        <li>Cross Platform (iOS/Android)</li>
                                        <li>Deployed to App Store (iOS) and Play Store (Android) or privately</li>
                                        <li>Customised Functionality (such as calculators, forms, product tracking, sales tools)</li>
                                        <li>Design customised to fit your companies branding</li>    
                                        <li>Integrate with back-end APIs/systems <br /><br /></li>                                         
                                    </ul>
                                    <Link to="/contact" className="theme-btn-s3">Enquire</Link>
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>                
            </div> 
        </section>
    )
}

export default PricingSection;