import React from 'react'
import {Link}  from 'react-router-dom'


import './style.css'

const Footer = (props) =>{
  return(
    <footer className={`site-footer ${props.subclass}`}>
        <div className="upper-footer">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-4 col-md-6 col-sm-12 col-12">
                        <div className="widget about-widget">
                        
                        <object data="/images/logo.svg" width="140px" style={{marginTop:-30}}  />
                          
                            <div style={{marginTop:'40px'}}>
                                <p>Auckland, New Zealand</p>
                            
                                <div className="social-icons" style={{float:'left'}}>
                                    <ul>                         
                                        <li><Link target="_blank" to={{ pathname: "https://www.linkedin.com/in/simon-watt-b400542"}}><i className="fa fa-linkedin"></i></Link></li>
                                        <li><Link target="_blank" to={{ pathname: "https://github.com/simonwatt"}}><i className="fa fa-github"></i></Link></li>  
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col col-lg-4 col-md-6 col-sm-12 col-12">
                        <div className="widget link-widget">
                            <div className="widget-title">
                                <h3>Company</h3>
                            </div>
                            <ul>
                                <li><Link to="/about">About</Link></li>
                                <li><Link to="/services">Services</Link></li>                                                     
                            </ul>
                            <ul>
                                <li><Link to="/case">Recent Work</Link></li>  
                                <li><Link to="/contact">Contact</Link></li>                                       
                            </ul>
                        </div>
                    </div>
                    <div className="col col-lg-4 col-md-6 col-sm-12 col-12">
                        <div className="widget link-widget">
                            <div className="widget-title">
                                <h3>Services</h3>
                            </div>
                            <ul>

                                <li><Link to="/services/websites">Websites</Link></li>
                                <li><Link to="/services/mobile-apps">Mobile Apps</Link></li>
                            </ul>
                            <ul>

                                <li><Link to="/services/pwa">Web Apps & PWAs</Link></li> 
                                <li><Link to="/services/software">Integrations</Link></li>   

                            </ul>
                        </div>
                    </div>
                   
                </div>
            </div> 
        </div>
        <div className="lower-footer">
            <div className="container">
                <div className="row">
                    <div className="separator"></div>
                    <div className="col col-xs-12">
                        <p className="copyright">Copyright &copy; {new Date().getFullYear()} SWATT Software Limited.</p>
                        <div className="extra-link">
                            <ul>
                                <li><Link to="/privacy">Privacy Policy</Link></li>                               
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  )
} 

export default Footer;