import React, { Component } from 'react';
import axios from 'axios';



class ContactForm extends Component {

    state = {
        name: '',
        email: '',
        phone: '',
        reason: '',
        comment: '',
        sent:false,  
        isSending: false     
    }


    changeHandler = (e) => {
       
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    subimtHandler = (e) => {

        this.setState({isSending: true});
        e.preventDefault();


        let that = this;

        const { 
            name,
            email,
            phone,
            reason,
            comment
            } = this.state;


        axios.post('https://api.bayssitter.nz/api/contact/SendEmail', {
            name: name,
            email: email,
            phone: phone,
            reason:reason,
            comment: comment
        }, {
            'Access-Control-Allow-Origin': '*'
        })
        .then(function (response) {
            that.setState({
                sent: true,
                isSending: false
            })
        }).catch(error => {
            console.log(error);
           alert('An error occurred submitting the form. Please try again, or contact us by email instead.');
           that.setState({sent: false, isSending: false});
        })     
        
    }

    render() {

        const { name,
            email,
            phone,
            reason,
            comment, sent, isSending } = this.state;

             
        return (
            <>
            { !sent &&
                <form onSubmit={this.subimtHandler}>
                    <div className="contact-form form-style row">
                        <div className="col-12 col-lg-6">
                            <input required type="text" value={name} onChange={this.changeHandler} placeholder="Your Name*" id="name" name="name" />                       
                        </div>
                        <div className="col col-lg-6">
                            <input type="text" placeholder="Phone" onChange={this.changeHandler} value={phone} id="phone" name="phone" />
                        
                        </div>
                        <div className="col-12  col-lg-6">
                            <input type="email" required placeholder="Your Email" onChange={this.changeHandler} value={email} id="email" name="email" />
                            
                        </div>
                        <div className="col col-lg-6">
                            <select className="form-control" onChange={this.changeHandler} required value={reason} name="reason">
                                <option disabled value="">I'm interested in</option>
                                <option value="Website">Website</option>
                                <option value="App">Mobile App</option>
                                <option value="PWA">Web App / PWA</option>
                                <option value="Software">Software / Integration</option>
                                <option value="Contracting">Contracting</option>
                                <option value="Other">Other</option>
                            </select>                       
                        </div>
                        <div className="col-12 col-sm-12">
                            <textarea className="contact-textarea" value={comment} onChange={this.changeHandler} placeholder="Your Message" required name="comment"></textarea>
                            
                        </div>
                        <div className="col-12">
                                <button type="submit" disabled={isSending} className="theme-btn-s4">Send</button>
                        </div>
                    </div>
                </form>
            }

            { sent &&
                <div>Thank you. The form was sent successfully. I will be in touch soon!</div>
            }
            </>

        )
    }
}

export default ContactForm;