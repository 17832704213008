import React from 'react';
import ContactForm from '../ContactForm'
import './style.css'

const Contactpage = () => {

    return(
        <div className="contact-page-area">
            <div className="contact-section-s3 section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-4 col-12">
                            <div className="contact-info">
                                <ul>
                                    <li className="d-none d-lg-block">
                                        <i className="fi flaticon-servers"></i>
                                        <h4>Location</h4>
                                        <p>SWATT Software is based in Auckland, New Zealand, but is available to work 
                                            remotely for clients worldwide.</p>
                                    </li>
                                    <li>
                                        <i className="fi flaticon-email-1"></i>
                                        <h4>Email Address</h4>
                                        <p>simon@swatt.co.nz</p>
                                    </li>                                
                                </ul>
                            </div>
                        </div>
                        <div className="col col-lg-8 col-12">
                            <div className="section-title">
                                <div className="icon">
                                    <i className="fi flaticon-balance"></i>
                                </div>
                                <span>Contact</span>
                                <h2>Need a Website or App?...</h2>
                                <p>Please get in touch and you will get a response within 12 hours. 
                                 </p>
                            </div>
                            <div className="contact-form">
                                <ContactForm/>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
           
        </div>
     )
        
}

export default Contactpage;
