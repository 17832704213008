import React, {Fragment} from 'react';
import Header from '../../components/header'
import PageTitle from '../../components/pagetitle'
import Privacy from '../../components/privacy'
import Footer from '../../components/footerTop';
import Scrollbar from '../../components/scrollbar'


const PrivacyPage =() => {
    return(
        <Fragment>
            <Header/>
            <PageTitle pageTitle={'Privacy'}/>
           
            <Privacy/>           
      
          
            <Footer subclass={'site-footer-s2'}/>
            <Scrollbar/>
        </Fragment>
    )
};
export default PrivacyPage;