import React from 'react';
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Homepage from '../HomePage'
import AboutPage from '../AboutPage'
import PrivacyPage from '../PrivacyPage'
import TestimonialPage from '../TestimonialPage'
import TestimonialPage2 from '../TestimonialPage2'
import CasePage from '../CasePage'
import CaseSinglePage from '../CaseSinglePage'
import TeamPage from '../TeamPage'
import ErrorPage from '../ErrorPage'       
import PricingPage from '../PricingPage'       
import ServicePage from '../ServicePage' 
import ContactPage from '../ContactPage'       

  
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const AllRoute = () => { 

  return (
    <div className="App">
       <Router>
          <ScrollToTop />
          <Switch>
            <Route exact path='/' component={Homepage}/>
            <Route path='/home' component={Homepage} />                    
            <Route path='/about' component={AboutPage} />
            <Route path='/testimonial1' component={TestimonialPage} />
            <Route path='/testimonial2' component={TestimonialPage2} />
            <Route path='/case' component={CasePage} />
            <Route path='/casesingle' component={CaseSinglePage}/>
            <Route path='/404' component={ErrorPage}/> 
            <Route path='/pricing' component={PricingPage}/> 
            <Route path='/services' component={ServicePage}/>   
            <Route path='/services/:serviceId' component={ServicePage}/>  
            <Route path='/contact' component={ContactPage}/> 
            <Route path='/privacy' component={PrivacyPage}/> 
          </Switch>
      </Router>
      
    </div>
  );
}

export default AllRoute;
