import React, {Fragment} from 'react';
import Header from '../../components/header'
import PageTitle from '../../components/pagetitle'
import Contactpage from '../../components/Contactpage'
import Footer from '../../components/footerTop';
import Scrollbar from '../../components/scrollbar'


const ContactPage =() => {
    return(
        <Fragment>
            <Header/>
            <PageTitle pageTitle={'Contact'}/>
            <Contactpage/>
            <Footer subclass={'site-footer-s2'}/>
            <Scrollbar/>
        </Fragment>
    )
};
export default ContactPage;