import React, {Fragment} from 'react';
import Header from '../../components/header'
import PageTitle from '../../components/pagetitle'
import Testimonials from '../../components/Testimonial'
import Footer from '../../components/footerTop';
import Scrollbar from '../../components/scrollbar'


const CasePage =() => {
    return(
        <Fragment>
            <Header/>
            <PageTitle pageTitle={'Recent Work'}/>
            <Testimonials/>
            <Footer subclass={'site-footer-s2'}/>
            <Scrollbar/>
        </Fragment>
    )
};
export default CasePage;