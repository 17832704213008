import React from 'react'
import {Link} from 'react-router-dom'
import './style.css'

const Privacy = (props) => {
    return(
        <section className="about-us-section section-padding">
            <div className="container" style={{marginTop:'40px'}}>
                <div className="row">
                    <div className="col col-lg-12 col-12">
                        <div className="section-title-s4">
                            <span>Privacy Policy</span>                          
                        </div>
                        <div className="about-details">
                        <h5> Privacy Policy</h5>
                        <p>
                        We (SWATT Software Limited) only collect personal information from you that you provide, including information about your:
                        <ul style={{listStyleType: 'disc', margin:'30px'}}>
                            <li>Contact information (including Name, email, phone) if submitted on our contact form.</li>
                        </ul>
                       
                        We collect your personal information in order:                        
                        <ul style={{listStyleType: 'disc', margin:'30px'}}>
                            <li>to deliver further information or services you have requested, or to to respond to communications from you. For example if you have submitted an enquiry or complaint through our website, or if you have requested further information on our services through our website<br /><br /></li>
                            <li>to market our services to you electronically (including via email), if you have agreed to this by selecting the agree option on the contact form.<br /><br /></li>
                           
                        </ul>
                      
                         You have the right to ask for a copy of any personal information we hold about you, and to ask for it to be corrected if you think it is wrong. If you’d like to ask for a copy of your information, or to have it corrected, please contact us at simon@swatt.co.nz.
                        </p>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
    )
}

export default Privacy;