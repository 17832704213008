import React from 'react'
/*import Logo from '../../images/logo.png'*/
import {Link}  from 'react-router-dom'
import MobileMenu from '../../components/MobileMenu'

import './style.css'

const Header = () => {
    const SubmitHandler = (e) =>{
        e.preventDefault()
     }

    return(	
	<div className="middle-header header-style-3">
        <div className="container-fluid">
            <div className="header-content">
              
                <nav className="d-lg-block d-none">
                <ul>
                        <li><Link to="/home" className={window.location.pathname == '/home' || window.location.pathname == '' ? 'active': ''} title="">Home</Link>                            
                        </li>
                        <li><Link to="/about" title="" className={window.location.pathname == '/about' ? 'active': ''}>About</Link>
                            <ul>
                                <li><Link to="/about" title="">About</Link></li>
                                <li><Link to="/contact" title="">Contact</Link></li>                              
                               
                            </ul>
                        </li>
                        <li><Link  to="/services" className={window.location.pathname.indexOf('services') > -1 ? 'active': ''}>Services</Link>
                            <ul>
                                <li><Link  to="/services">Services</Link></li>
                                <li><Link  to="/services/websites">Websites</Link></li>
                                <li><Link  to="/services/mobile-apps">Mobile Apps</Link></li>
                                <li><Link  to="/services/pwa">Web Apps & PWAs</Link></li>
                                <li><Link  to="/services/hosting">Domain Registration & Website Hosting</Link></li>                                
                                <li><Link  to="/services/software">Integrations</Link></li>
                                <li><Link  to="/services/contract">Contracting</Link></li>
                            </ul>
                        </li>
                        <li><Link to="/case" className={window.location.pathname == '/case' ? 'active': ''}>Recent Work</Link>                           
                        </li>   
                        <li><Link to="/contact" className={window.location.pathname == '/contact' ? 'active': ''}>Contact</Link>                           
                        </li>           
                      
                    </ul>
                </nav>
                <div className="contact">
                    <div className="cart-search-contact">                     
                          <Link to="/contact" className="theme-btn-s2">Contact us <i className="ti-angle-right"></i></Link>
                    </div>
                </div>
                <div className="clearfix"></div>
            </div>
            <MobileMenu/>
        </div>
    </div>
    )
}

export default Header;