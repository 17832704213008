import React from 'react'

import './style.css'

const WhyChooseUs1 = (props) => {
    return(
        <section className={`why-choose-us-s1 section-padding ${props.abclass}`}>
            <div className="container">
                <div className="row">
                    <div className="col col-lg-10 col-md-10 col-12">
                        <div className="section-title">
                            <span>Why choose swatt.co.nz</span>                            
                                          
                           
                        </div>
                        <div className="why-choose-grids">
                            <div className="grid">
                                <div className="icon">
                                    <i className="fi flaticon-startup"></i>
                                </div>
                                <h3>Simplicity</h3>
                                <p>Launching an App or Website can be an intimidating process, 
                                    but I can help you with 100% of the process - 
                                    even if you have no technical knowledge at all.

                                </p>
                            </div>
                            <div className="grid">
                                <div className="icon">
                                    <i className="fi flaticon-skills"></i>
                                </div>
                                <h3>Diverse Skill Set</h3>
                                <p>Experience in multiple digital disciplines means I can take care of everything 
                                    from initial concept to delivery. It also means
                                    I can help you understand how you can integrate your website/app with other systems your business uses.
                                </p>
                            </div>
                            <div className="grid">
                                <div className="icon">
                                    <i className="fi flaticon-best-price"></i>
                                </div>
                                <h3>Cost Effective</h3>
                                <p>Missing out the 'middlemen' of a typical digital agency means I can compete favourably in price yet still deliver
                                high quality solutions.</p>
                            </div>
                        </div>
                    </div>
                    <div className="d-none d-md-block col col-md-2 col-12">
                        <div className="img-holder">
                            <img style={{marginTop:'60px', marginLeft:'20px'}} src='/images/rocket4-small.png' alt="Rocket"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WhyChooseUs1;