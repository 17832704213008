import React, {Fragment} from 'react';
import Header1 from '../../components/header'
import PageTitle from '../../components/pagetitle'
import ServicesSection from '../../components/ServicesSection'
import Footer from '../../components/footerTop';
import Scrollbar from '../../components/scrollbar'



const ServicePage =() => {

let description = <p>I provide a range of services. Select the service you are interested in for more information.</p>
    return(
        <Fragment>
            <Header1/>
            <PageTitle pageTitle={'Services'} pageDescription={description}/>           
            <ServicesSection isPage={true} />      

            <Footer subclass={'site-footer-s2'}/>
            <Scrollbar/>
        </Fragment>
    )
};
export default ServicePage;